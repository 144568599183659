import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    //PRODUCTION SERVER.
    ApiService.vueInstance.axios.defaults.baseURL =
       process.env.NODE_ENV === "production"
         ? "https://czoneapi.cd14.net/"
         : "https://czoneapi.cd14.net/";    

    //UAT SERVER
    //ApiService.vueInstance.axios.defaults.baseURL =
    //  process.env.NODE_ENV === "production"
    //    ? "https://uat-czoneapi.cd14.net/"
    //    : "https://uat-czoneapi.cd14.net/";

    //DEVELOPMENT SERVER
    //ApiService.vueInstance.axios.defaults.baseURL =
    // process.env.NODE_ENV === "production"
    //      ? "https://localhost:44325/"
    //      : "https://localhost:44325/";

    app.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.message && error.message === "Network Error") {
          // router.push({ path: '/login' })
          return Promise.reject(
            "Web server is down. Please contact support team!"
          );
        }
        if (error.response && error.response.status === 401) {
          router.push({ path: "/404" });
          return Promise.reject(
            "Authorization has been denied for this request."
          );
        } else {
          if (
            error.response.status === 500 &&
            error.response.data.ErrorCode &&
            error.response.data.ErrorCode === 1000
          ) {
            return Promise.reject(error.response.data.ErrorDescription);
          } else {
            return Promise.reject(error.response ? error.response.data : error);
          }
        }
      }
    );
  }

  /**
   * Get authentication Token from session storage
   * @returns authentication key
   */
  public static getToken(): string | null {
    return sessionStorage.getItem("AccessToken")
      ? sessionStorage.getItem("AccessToken")
      : null;
  }

  /**
   * set authentication Token from Api call
   * @returns void
   */
  public static setToken(email, password): void {
    axios
      .post(
        "czonetoken",
        `username=${email}&password=${password}&grant_type=password`
      )
      .then(({ data }) => {
        sessionStorage.setItem("AccessToken", data.access_token);
      })
      .catch(({ response }) => {
        alert(response.data.errors);
      });
  }
  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = `text/html`;

    ApiService.vueInstance.axios.defaults.headers.common[
      "Access-Control-Allow-Origin"
    ] = `*`;

    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${ApiService.getToken()}`;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static postT(
    resource: string,
    params: { accountid: string; fromdate: string; todate: string }
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
